interface VimConfig {
  clientId: string;
  clientSecret: string;
  host: string;
  wsHost: string;
  httProtocol: string;
  wsProtocol: string;
  scope: string;
  httPort: number;
  wsPort: number;
}

const vimConfig: VimConfig = {
  clientId: "app",
  clientSecret: "app",
  host: "im-dev-api.maxai.cc",
  wsHost: "im-wss-dev.maxai.cc",
  httProtocol: "https",
  wsProtocol: "wss",
  scope: "server",
  httPort: 443,
  wsPort: 9326,
};
export default vimConfig;
